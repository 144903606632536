import React from "react";
import "./components/Stake.css";

const Faq = () => {

    return (
        <div className="stake faq">
            <details open>
                <summary>How to stake</summary>
                <div class="faq__content">
                    <p className="steps"><b>Step 1:</b> Visit <a href="https://stake.furukuru.io/FukuStaking"> https://stake.furukuru.io/FukuStaking</a> and Connect your Metamask wallet to BSC Mainnet.</p>
                    <p className="steps"><b>Step 2:</b> Make sure you have $FUKU token to stake.</p>
                    <p className="steps"><b>Step 3:</b> Click on Approve button to approve your $FUKU to be staked and confirm via Metamask pop up.</p>
                    <p className="steps"><b>Step 4:</b> Enter the number of tokens to be staked and submit by clicking Stake button</p>
                    <p className="steps"><b>Step 5:</b> Confirm the Metamask pop-up and you are done. </p>
                </div>
            </details>
            <details>
                <summary>How to re-invest</summary>
                <div class="faq__content">
                    <p className="steps"><b>Step 1:</b> Visit <a href="https://stake.furukuru.io/FukuStaking"> https://stake.furukuru.io/FukuStaking</a> and Connect your Metamask wallet to BSC Mainnet.</p>
                    <p className="steps"><b>Step 2:</b> Select Stake option and if you haven't staked before follow 'How to stake' instructions above.</p>
                    <p className="steps"><b>Step 3:</b> You ll find the rewards earn by staking on the left.</p>
                    <p className="steps"><b>Step 4:</b> Click on the 'ReInvest' button and confirm the Metamask pop-up to restake the earned rewards.</p>
                </div>
            </details>
            <details>
                <summary>How to unstake</summary>
                <div class="faq__content">
                <p className="steps"><b>Step 1:</b> Visit <a href="https://stake.furukuru.io/FukuStaking"> https://stake.furukuru.io/FukuStaking</a> and Connect your Metamask wallet to BSC Mainnet.</p>
                <p className="steps"><b>Step 2:</b> Select UnStake option and if you haven't staked before follow 'How to stake' instructions above.</p>
                <p className="steps"><b>Step 3:</b> Enter the number of tokens to be unstaked and submit by clicking Unstake button.</p>
                <p className="steps"><b>Step 4:</b> Confirm the Metamask pop-up and your unstaked tokens will be claimable post unbound period of 30 days</p>
                </div>
            </details>
            <details>
                <summary>How to claim</summary>
                <div class="faq__content">
                <p className="steps"><b>Step 1:</b> Visit <a href="https://stake.furukuru.io/FukuStaking"> https://stake.furukuru.io/FukuStaking</a> and Connect your Metamask wallet to BSC Mainnet.</p>
                <p className="steps"><b>Step 2:</b> Select UnStake option and if you haven't unstaked before follow 'How to Unstake' instructions above.</p>
                <p className="steps"><b>Step 3:</b> You ll find the unstaked balance & unstaked claimable on the left.</p>
                <p className="steps"><b>Step 4:</b> Click on the 'Claim' button and confirm the Metamask pop-up to claim the unstaked claimable tokens post unbound period.</p>
                </div>
            </details>
        </div>
    );
};

export default Faq;
