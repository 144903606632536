export const CoreV0 = "0x0A7eE863b107B67ef358a54b0eC3D39a8304A600";
export const FKToken = "0xec181b5f1d7b069192a3554bde509728b16d5d73";//FUKU MAINNET TOKEN
export const StakeFUKUV0 = "0xd79AE7EaD27B18ac2e228cDF8cdC0499BFdc538b";// FUKU Staking
export const StakeV2 ="0xE0a2A80bD6DA2204c472178F8b0033e671891bF7";// LP Staking
export const CakeV0 ="0x91bd9CCBFab019E2Dbf801d270d09681B36240e5";
export const CakeV2 ="0x2f62D053dc8c444d3da51682b008aA60442D918d";// LP STAKING
export const Busd = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
/** Rafflee */
export const raffleAdd = "0x00369E8bd75301E7441B5f9402C062a6fe2E3EC9";

/**Stake V2-60% */
export const StakeFUKUV2 = "0x0E8C934440deceE50F9a4A4a1d69CD54a89f27B1";
//export const FKToken = "0xF2EabE1826571ef004fcB9Ae6166C2eA312343e0";//test