import React, { useState } from "react";

import Web3 from "web3";
import Web3Modal from "web3modal";
import { FKToken, StakeV2 } from "./Contract/constant";
import { invABI, Ierc20, stakeV0ABI } from "./Contract/abi/abi";
import buildcanvas from './images/build-canvas.svg'
import FuruLogo from "./images/furukurulogo.svg";
import PancakeLogo from "./images/pancakeswap.png";

import raffleticket from './images/raf2.svg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
function Home() {
  const [state, setState] = useState();

  async function init() {
    console.log("starting up");
    //console.log("WalletConnectProvider is", WalletConnectProvider);
    //console.log("Fortmatic is", Fortmatic);

    const providerOptions = {
      metamask: {},
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      //disableInjectedProvider: false,
      providerOptions,
      theme: "dark", // required
    });

    const provider = await web3Modal.connect();
    //console.log(provider);

    const web3 = new Web3(provider);
    //console.log(modal)

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    if (networkId !== 56) {
      alert("Switch to bsc network");
      web3Modal.clearCachedProvider();
      web3Modal.resetState();
      return;
    }
    const balance = await web3.eth.getBalance(address);
    console.log(address);
    console.log(balance);
    //  console.log(networkId)
    //const inv = new web3.eth.Contract(invABI, InvestorCon);
    const fk = new web3.eth.Contract(Ierc20, FKToken);
    const stkv0 = new web3.eth.Contract(stakeV0ABI, StakeV2);
    setState({
      web3,
      provider,
      connected: true,
      address,
      web3Modal,
      networkId,
      fk,
      stkv0
    });
    // window.provider = provider;
    await subscribeProvider(provider);
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      //if(state !== undefined && state.web3 !== undefined)
      //  console.log(state);  
      //setProviderData({ address: accounts[0] });
      //setState({ address: accounts[0] });
      init();
    });
    provider.on("chainChanged", async (chainId) => {
      // const { web3 } = this.state;
      //const networkId = await web3.eth.net.getId();
      // setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("networkChanged", async (networkId) => {
      //const { web3 } = this.state;
      //const chainId = await web3.eth.chainId();
      //  await this.setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
  };

  const onConnect = async () => {
    try {
      await init();
    } catch (err) {
      console.log(err);
      alert("failed to connect");
    }
  };

  const logout = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  return (
    <div className="stake">
      <div className="stake_container" style={{ paddingTop: "50px" }}>
        <div className="scontainer">
          <div className="row card-wrapper">
            
            <div className="cols card-div">
              <img src={buildcanvas} alt="" className="card-img" />
              <div className="img_cont homecardimg">
                <img src={FuruLogo} alt="furu Image" />
              </div>
              <h3 className="homecarddesc">Stake $FUKU token and get $FUKU token as reward</h3>
              <hr />
              <div className="buttons_sec">
                <Link to="/Stakev2">
                  <div className="cardbtn cardroutebtn" >Stake $FUKU</div>
                </Link>
              </div>
            </div>
            <div className="cols card-div">
              <img src={buildcanvas} alt="" className="card-img" />
              <div className="img_cont homecardimg"  >
                <img src={raffleticket} alt="Pancake Image"/>
              </div>
              <h3 className="homecarddesc">Buy raffle tickets and win upto $10,000</h3>
              <hr />
              <div className="buttons_sec">
                <Link to="/raffle"><div className="cardbtn cardroutebtn" >Raffle</div></Link>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
