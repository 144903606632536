import React, { useState, useContext, useEffect } from "react";
import "./components/Stake.css";
import { Web3Context } from "./Context/Web3context";
import { StakeFUKUV0 } from "./Contract/constant";
//import { CakePair } from "./Contract/abi/abi";
import tablebg from './images/tablebg.png'
import horiline from './images/hori-line.png'
import buildcanvas from './images/build-canvas.svg'
import FuruLogo from "./images/furukurulogo.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { TrendingUpTwoTone } from "@material-ui/icons";
//import PancakeLogo from "./images/pancakeswap.png";

const FukuStake = () => {
  const [isUpload, setIsUpload] = React.useState(false)
  const [isUnstaked, setIsUnstaked] = React.useState(false)
  const [isReinvest, setIsReinvest] = React.useState(false)
  const [isClaimed, setIsClaimed] = React.useState(false)
  const [isApp, setIsApp] = React.useState(false)
  const copyAddress = () => {

    setTimeout(() => {

    }, 1000);
  };
  const state = useContext(Web3Context);
  const [tvl, setTVL] = useState(0);
  const [endTime, setEndTime] = useState('');
  const [isApproved, setApprove] = useState(false);
  const [availLP, setAvailLP] = useState(0);
  const [stakedLP, setStakeLP] = useState(0);
  const [unclaimed, setUnclaimed] = useState(0);
  const [totalUnstaked, setTotalUnstaked] = useState();
  const [totalUnstakedClaimable, setTotalUnstakedClaimable] = useState(0);
  const [totalUnstakedClaimableToken,setTotalUnstakedClaimableToken]=useState(0);
  const [toggle, setToggle] = useState(true);
  const [inputValue, setInputValue] = useState();
  const [furukuruPrice, setFurukuruPrice] = useState(0.004)
  const fetchFUKUPrice = () => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=furukuru&vs_currencies=usd')
      .then(response => response.json())
      .then(data => setFurukuruPrice(data.furukuru.usd));
  }
  fetchFUKUPrice()

  const getTVL = (stk) => {
    stk.methods.totalSupply().call({ from: state.address }).then((r) => {
      let _tvl = state.web3.utils.fromWei(r.toString(), "ether");
      console.log(_tvl)
      _tvl = _tvl * furukuruPrice;

      //console.log(pd);
      setTVL(parseFloat(_tvl).toFixed(3));
      getEndTime(stk);

    })
  }
  const getEndTime = (stk) => {
    //periodFinish
    stk.methods.periodFinish().call({ from: state.address }).then((r) => {
      //let _tvl = state.web3.utils.fromWei(r.toString(), "ether");
      //_tvl = parseFloat(_tvl).toFixed(3);
      var utcSeconds = r;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      setEndTime(d.toDateString());

    })
  }
  const _stakedLP = (stk) => {
    stk.methods.users(state.address).call({ from: state.address }).then((r) => {
      //alert(JSON.stringify(r['stakeToken']));
      let stkedLP = state.web3.utils.fromWei(r['stakeToken'], "ether");
      //console.log("Staked",state.web3.utils.fromWei(r.toString(), "ether"));
      setStakeLP(parseFloat(stkedLP).toFixed(3));
      if (parseFloat(stkedLP) > 0)
        setApprove(true);
    });
    _unclaimed(stk);
    _totalUnstakedAmountReadyToClaim(stk);
    //_totalUnstakedAmount(stk);

  }

  const _totalUnstakedAmountReadyToClaim = (stk) => {
    stk.methods.totalUnstakedAmountReadyToClaim(state.address).call({ from: state.address }).then((r) => {
      let e = state.web3.utils.fromWei(r.toString(), "ether");
      setTotalUnstakedClaimableToken(parseFloat(e).toFixed(3));

    })
  }
  const _totalUnstakedAmount = (stk) => {
    stk.methods.totalUnstakedAmount(state.address).call({ from: state.address }).then((r) => {
      let e = state.web3.utils.fromWei(r.toString(), "ether");
      setTotalUnstaked(parseFloat(e).toFixed(3));

    })
  }
  const _unclaimed = (stk) => {
    stk.methods.earned(state.address).call({ from: state.address }).then((r) => {
      console.log("Staked", state.web3.utils.fromWei(r.toString(), "ether"));

      let e = state.web3.utils.fromWei(r.toString(), "ether");
      setUnclaimed(parseFloat(e).toFixed(3));
    })
  }

  const reInvest = () => {
    setIsReinvest(true)
    if (state !== undefined) {
      const stk = state.fkstake;
      stk.methods.reinvest().send({ from: state.address }).then((r) => {
        console.log(r)
        setIsReinvest(false)
      });
    }
  }
  /*
  const claimRewards = () => {
    setIsClaimed(true)
    if (state !== undefined) {
      const stk = state.fkstake;
      stk.methods.getReward().send({ from: state.address }).then((r) => {
        console.log(r);
        /* Rinkesh Bhai 
        _stakedLP(stk);
        setIsClaimed(false)
      });
    }
  }
  */
  const claimRewards = () => {
    setIsClaimed(true)
    if (state !== undefined) {
      const stk = state.fkstake;
      stk.methods.claimUnstakedAmount().send({ from: state.address }).then((r) => {
        console.log(r);
        /* Rinkesh Bhai */
        _stakedLP(stk);
        setIsClaimed(false)
      });
    }
  }
  const _stake = () => {
    setIsUpload(true)
    console.log(isUpload)
    if (state !== undefined && inputValue > 0) {
      let numStr = inputValue.toString();
      let ethValue = state.web3.utils.toWei(numStr, "ether");
      const stk = state.fkstake;
      stk.methods.stake(ethValue).send({ from: state.address }).then((r) => {
        console.log(r);
        _stakedLP(stk);
        setIsUpload(false)
        getTVL(stk);
      })
    }

  }
  const _unstake = () => {
    setIsUnstaked(true)
    if (state !== undefined && inputValue > 0) {
      console.log(inputValue);
      let numStr = inputValue.toString();
      let ethValue = state.web3.utils.toWei(numStr, "ether");
      const stk = state.fkstake;
      stk.methods.unstake(ethValue).send({ from: state.address }).then((r) => {
        console.log(r);
        _stakedLP(stk);
        setIsUnstaked(false)
        getTVL(stk);
      })
    }
  }
  const _unstakeExit = ()=>{
    setIsUnstaked(true)
    if (state !== undefined ) {
      //console.log(inputValue);
      //let numStr = inputValue.toString();
     //let ethValue = state.web3.utils.toWei(numStr, "ether");
      const stk = state.stkv0;
      stk.methods.exit().send({ from: state.address }).then((r) => {
        console.log(r);
        _stakedLP(stk);
        getTVL(stk);
      })
    }
  }

  const onInputValue = (e) => {
    setInputValue(e.target.value);
  }
  const approveFUKUToken = () => {
    setIsApp(TrendingUpTwoTone)
    if (state !== undefined) {
      const FUKU = state.fk;
      let approveLimit = state.web3.utils.toWei("100000000000", "ether");

      FUKU.methods.approve(StakeFUKUV0, approveLimit).send({ from: state.address }).then((r) => {
        setApprove(true);
        setIsApp(false)
      })

    }

  }

  useEffect(() => {
    if (state !== undefined) {

      //const inv = state.inv;
      const fk = state.fk;
      const stk = state.fkstake;
      getTVL(stk);
      _stakedLP(stk);
      stk.methods.earned(state.address).call({ from: state.address }).then((r) => {
        console.log("Available LP", state.web3.utils.fromWei(r.toString(), "ether"));
        let e = parseFloat(state.web3.utils.fromWei(r.toString(), "ether")).toFixed(3)
        setAvailLP(e);
      })

      stk.methods.userUnstake(state.address).call({from:state.address}).then((r)=>{
        console.log(r.unstakingAmount,(parseInt(r.unstakingTime)));
        let e = state.web3.utils.fromWei(r.unstakingAmount.toString(), "ether");
        setTotalUnstakedClaimable(parseFloat(e).toFixed(1));
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(parseInt(r.unstakingTime));
        setTotalUnstaked(d.toDateString());
  

      })
      /*
      fk.methods.balanceOf(state.address).call({ from: state.address }).then((r) => {
        console.log("Available LP", state.web3.utils.fromWei(r.toString(), "ether"));
        let e = parseFloat(state.web3.utils.fromWei(r.toString(), "ether")).toFixed(3)
        setAvailLP(e);
      })*/

    }
  }, [state])
  return (
    <div className="stake">
      <div className="stake_container">
        <div className="row" style={{ margin: "0 !important" }}>
          <div className="col">
            <h1 className="lpstaking-title" >$FUKU - Staking</h1>
            <h6 className="lpstaking-subtitle">Stake your $FUKU tokens to earn extra $FUKU</h6>
          </div>
        </div>
        <div className="row">
          <div className="tablebg">
            <img src={tablebg} alt="" className="tablebgimg" />
            <div className="row table-title" >
              <div className="col table-text">TVL</div>
              <div className="col table-text">APY</div>
              <div className="col table-text1">End Time</div>
            </div>
            <div className="row table-line">
              <img src={horiline} alt="" className="horiline" />
            </div>
            <div className="row table-content">
              <div className="col table-text">{tvl + " $"} </div>
              {/* <div className="col table-text">{apy.toFixed(2) + "%"}</div> */}
              <div className="col table-text">500%</div>
              <div className="col table-text1">{endTime}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="create_c">
        <div className="cc">
          <h4 className="create-lp">Create $FUKU-BUSD CAKE LP tokens</h4>
          <p className="provide-liq">Provide liquidity in Pancakeswap to get LP tokens.</p>
        </div>
        <div className="cc2">
          <a
            href="https://exchange.pancakeswap.finance//#/add/0x3A50d6daacc82f17A2434184fE904fC45542A734/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
            target="blank"
            className="addliq-a"
          >
            <img src={addliq} alt="" className="addliq" />
            <div className="add-liq-btn">Add $FUKU-BUSD Liquidity</div>
          </a>
        </div>
      </div> */}
      {state ?
        <>
          <div className="">
            <div className="unboundtext">
              <p style={{ color: "red", fontSize: "16px" }}>
                <b>* Note:</b> Unbounding period is 30 days in unclaim stake, Staked tokens will be available to unstake on first week of January, 2022.
              </p>
            </div>
            <div className="row card-wrapper">
              <div className="cols card-div">
                <img src={buildcanvas} alt="" className="card-img" />
                <div className="img_cont">
                  <img src={FuruLogo} alt="Pancake Image" />
                </div>
                <div className="col_descd">
                  <p style={{ color: "#B42D17", display: "flex" }}>
                    $FUKU Staked: {stakedLP}
                    <>
                      <CopyToClipboard
                        text={stakedLP ? stakedLP : ""}
                        onCopy={copyAddress}
                      >
                        <FileCopyOutlinedIcon
                          style={{ cursor: "pointer", paddingTop: "5px" }}
                        />
                      </CopyToClipboard>
                    </>
                  </p>
                  <span style={{ color: "#B42D17", marginTop: "10px" }}>
                    Rewards: {availLP}
                    <>
                      <CopyToClipboard
                        text={availLP ? availLP : ""}
                        onCopy={copyAddress}
                      >
                        <FileCopyOutlinedIcon
                          style={{ cursor: "pointer", marginTop: "-5px", paddingTop: "5px" }}
                        />
                      </CopyToClipboard>
                    </>
                  </span>
                </div>
                <hr />
                {/* Rinkesh Bhai this code */}
                <div className="buttons_sec">
                  <div className="row">
                    {/* <div className="row  staketoggle1"> <a style={{ color: "blue", cursor: "pointer" }} onClick={() => setToggle(false)}><input checked={toggle === false} type="radio" name="stack" className="radios" />stake</a></div>
                    <div className="row  staketoggle2"> <a style={{ color: "red", cursor: "pointer" }} onClick={() => setToggle(true)}><input checked={toggle === true} type="radio" name="stack" className="radios" />unstake</a></div> */}
                    <div className="row">
                      {/* <div className="row  staketoggle1"> <a style={{ color: "blue", cursor: "pointer" }} onClick={() => setToggle(false)}><input checked={toggle === false} type="radio" name="stack" className="radios" />stake</a></div> */}
                      <div className="row  staketoggle2"> <a style={{ color: "red", cursor: "pointer" }} ><input checked={toggle === true} type="radio" name="stack" className="radios" />unstake</a></div>
                      <div className="row">
                        {toggle ? <>
                          {/* this section has to be visible */}
                         
                          <div className="cardbtn unstakebtn" onClick={_unstakeExit}>{isUnstaked ? "Loading" : "Unstake"}</div>
                        </> : <>
                          {isApproved ?
                            <>
                              <input className=" unstaketxt" onChange={onInputValue} type="number" placeholder="Amt of FUKU to Stake"></input>
                              {isUpload ? <>
                                <div className="cardbtn unstakebtn" onClick={_stake}>Loading...</div>
                              </> :
                                <>
                                  <div className="cardbtn unstakebtn" onClick={_stake}>Stake</div>
                                </>}
                            </>
                            :
                            <>
                              {availLP > 0 ?
                                <div className="cardbtn unstakebtn" onClick={approveFUKUToken}>{isApp ? "Loading" : "Approve"}</div> :
                                <a href="https://pancakeswap.finance/swap?outputCurrency=0xec181b5f1d7b069192a3554bde509728b16d5d73" target="_blank">
                                  <div className="cardbtn unstakebtn" style={{ width: '150px', marginLeft: "-30px" }}>BUY $FUKU</div>
                                </a>
                              }
                            </>}
                        </>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cols card-div">
                <img src={buildcanvas} alt="" className="card-img" />
                <div className="img_cont">
                  <img src={FuruLogo} alt="furu Image" />
                </div>
                {toggle ? <>
                  <div style={{ marginLeft: "-5px !important" }} className="col_descd">
                    <p style={{ color: "#B42D17", marginTop: "20px" }}>
                      Claimable on: <b>{totalUnstaked}</b>

                    </p>
                    <p style={{ color: "#B42D17", marginTop: "10px" }}>
                      Unstaked Amount: <b>{totalUnstakedClaimable}</b>

                    </p>
                    <p style={{ color: "red", marginTop: "10px", fontSize: "12px", width: "200px", lineHeight: "12px" }}>
                      <b>* Note:</b> Unstaking $FUKU doesn't yield any rewards and cannot be reinvested.
                    </p>
                  </div>
                  <hr />
                  <div className="buttons_sec">
                    {totalUnstakedClaimableToken > 0 ?
                      <div className="cardbtn claimbtn" onClick={claimRewards} >{isClaimed ? "Loading" : "Claim Rewards"}</div>
                    : <div className="cardbtn claimbtn" disabled> {isClaimed ? "Loading" : "Claim"} </div>}
                    
                  </div>

                </>
                  : <>
                    <div className="col_descd">
                      <p style={{ color: "#B42D17", marginTop: "40px" }}>
                        Unclaimed Reward: </p><p style={{ color: "#B42D17", marginTop: "10px" }}><b>{unclaimed}</b> $FUKU

                      </p>
                    </div>
                    <hr />
                    <div className="buttons_sec">
                      <div className="cardbtn claimbtn" onClick={reInvest}> {isReinvest ? "Loading" : "ReInvest"}</div>
                    </div>

                  </>}

              </div>
            </div>
          </div>
        </> : <>
          <div className="col wnc-div"><h4 className="wallet-not-connected">Please connect to the Metamask Wallet</h4></div>
        </>}
    </div>
  );
};

export default FukuStake;
