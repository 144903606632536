import React, { useState, useContext } from "react";

import BtmNav from "./BtmNav";
import { NavLink } from "react-router-dom";
import { Web3Context } from "../Context/Web3context";
//import {NFTSearchData} from "../Context/NFTSearchData";

const Navbar = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const state = useContext(Web3Context);

  return (
    <>
      <div className="nav">
        <div className="nav-1">
          <nav className="nav-2 nav-3">
            <div className="head-left head-nav">
              <NavLink exact to="/" className="head-name">
                <h1 className="logo">FURUKURU</h1>
              </NavLink>

            </div>
            <div style={{ overflowY: "visible" }} className="nav-right">
              <div style={{ justifyContent: "center" }} className="home-nav">
                <div style={{ opacity: 0 }} className="nav-h1 nav-sm">
                  <div className="nav-sr">
                    <div className="nav-ser">
                      <div className="nav-sear"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="right-cnt">
              
              <div className="nav-item">
                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/"
                  className="home-btn hm"
                >
                  <span className="hm-txt">Stake</span>
                </NavLink>
              </div>
              <div className="nav-item">
                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/raffle"
                  className="home-btn hm"
                >
                  <span className="hm-txt">Raffle</span>
                </NavLink>
              </div>
              <div className="nav-item">
                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/leaderboard"
                  className="home-btn hm"
                >
                  <span className="hm-txt">Leaderboard</span>
                </NavLink>
              </div>
              <div className="nav-item">
                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/faq"
                  className="home-btn hm"
                >
                  <span className="hm-txt">FAQ</span>
                </NavLink>
              </div>
              {
                /* Connect Button */
                state ? (
                  <>
                    {/* <div className="cr-btn" onClick={() => setModalShow(true)}> */}
                    {/* <a href="#" className="btn-cr">
                        Create
                      </a> */}
                    <div class="mr-4">
                      {/*
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{ backgroundColor: "none" }}
                          id="dropdown-basic"
                        >
                          Create
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ backgroundColor: "aliceblue" }}>
                          <Dropdown.Item
                            onClick={() => setModalShow(true)}
                            href="#/action-1"
                          >
                            List NFT
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <NavLink
                              exact
                              to="/mintnft"
                              style={{ color: "#000", textDecoration: "none" }}
                            >
                              Mint NFT
                            </NavLink>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <CreateModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                    {/*
                    <div className="rr-btn">
                      <button
                        onClick={(e) => {
                          setToggle(!(toggle));
                        }}
                        className="btn-cc"
                      >
                        {props.web3State.address.substring(0, 5)}
                      </button>
                    </div>
                  
                      <AccountIcon accAddress={state.address} />*/}
                      <div className="btn-cc">
                        <p className="connect-text2">{state.address.substring(0, 7)}</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {
                        <div onClick={props.onConnect} className="btn-cc">
                          <p className="connect-text1">Connect Wallet</p>
                        </div>
                      }
                    </div>
                  </>
                )
              }
            </div>
          </nav>
        </div>
      </div>

      {/* Mobile view Nav Code */}
      <BtmNav web3State={state} onConnect={props.onConnect} />
    </>
  );
};

export default Navbar;
