import React from "react";
import { Component } from "react";
import "./components/Stake.css";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const truncate = (str, n) => {
    return (
      str.substr(0, n + 1) + "..." + str.substr(str.length - n, str.length)
    );
  };

  let leadersboard = [
    {
      Rank: 1,
      Address: "0xcc1a252783a205db438b5df0292535aa3478ce69",
      Staked: "12127125.39"
    },
      {
        Rank: 2,
        Address: "0xe23f13ca2d08d3b1cde41809a57dd34fa6861fe8",
        Staked: "12127125.39"
      },
      {
        Rank: 3,
        Address: "0x82ba7508f7f1995ab1623258d66cb4e2b2b8f467",
        Staked: "9931093"
      },
      {
        Rank: 4,
        Address: "0xad6736542c5ca4ff151f664e220056d06b530757",
        Staked: "9272490.287"
      },
      {
        Rank: 5,
        Address: "0x055f5afc1d6fa73b283548354593910c5f6c26ea",
        Staked: "7516370.792"
      },
      {
        Rank: 6,
        Address: "0xb2c906999c0c76ad9d23037640eab0d73d18692f",
        Staked: "6000000"
      },
      {
        Rank: 7,
        Address: "0xd745b071d9188f57feda378020173faed9dbf327",
        Staked: "5929578.377"
      },
      {
        Rank: 8,
        Address: "0x33de1969eeafb4c3b434edd3f76077ce43d91454",
        Staked: "4944174.676"
      },
      {
        Rank: 9,
        Address: "0xbc6867a88b1bbca8651308a5d06077065b8090df",
        Staked: "4862105.393"
      },
      {
        Rank: 10,
        Address: "0xc28013c1d7f0bcbd3ecb4d4926ca36ee88df8af2",
        Staked: "4845596.97"
      }
    ];
  
const getLeaders = leadersboard.map(function(item, idx) {
    const copyAddress = () => {
        setTimeout(() => {
        }, 1000);
      };
    return (
        <div className="leader" key={idx}>
            <h5 className="leader-rank">{item.Rank}.</h5>
            <h5 className="leader-address">
                <span className="truncated-address">{truncate(item.Address, 5)}</span>
                <span className="full-address">{item.Address}</span>
                <>
                    <CopyToClipboard
                    text={item.Address ? item.Address : ""}
                    onCopy={copyAddress}
                    >
                    <FileCopyOutlinedIcon
                        style={{ cursor: "pointer", marginLeft:"10px" }}
                    />
                    </CopyToClipboard>
                </> 
            </h5>
        </div>
    );
});
class Leaderboard extends Component{
   
    render(){
        return (
            <div className="leader-wrapper">
                <div className="notes-div">
                    <h4 className="leader-notes">Congratulations Winners</h4>
                </div>
                <div className="leader">
                    <h4 className="leader-rank" style={{ color: "#B42D17" }}>Rank #</h4>
                    <h4 className="leader-address" style={{ color: "#B42D17" }}>Address</h4>
                </div>
                {getLeaders}
            </div>
        );
    }
};

export default Leaderboard;
