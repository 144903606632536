import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { Web3Context } from "./Context/Web3context";

import Home from "./Home";
import Stake from "./Stake";
import FukuStake from "./FukuStake";
import Stakev2 from "./Stakev2";
import {FKToken,StakeFUKUV0,StakeFUKUV2  } from "./Contract/constant";
import {Ierc20,stakeV0ABI,stakeFKV0ABI} from "./Contract/abi/abi";
import Faq from "./Faq";
import Leaderboard from "./Leaderboard";
import Raffle from "./Raffle";

function App() {
  const [state, setState] = useState();

  async function init() {
    console.log("starting up");
    //console.log("WalletConnectProvider is", WalletConnectProvider);
    //console.log("Fortmatic is", Fortmatic);

    const providerOptions = {
      metamask: {},
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      //disableInjectedProvider: false,
      providerOptions,
      theme: "dark", // required
    });

    const provider = await web3Modal.connect();
    //console.log(provider);

    const web3 = new Web3(provider);
    //console.log(modal)

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    if (networkId !== 56) {
      alert("Switch to bsc mainnet network");
      web3Modal.clearCachedProvider();
      web3Modal.resetState();
      return;
    }
    const balance = await web3.eth.getBalance(address);
    console.log(address);
    console.log(balance);
    //  console.log(networkId)
    //const inv = new web3.eth.Contract(invABI, InvestorCon);
    const fk = new web3.eth.Contract(Ierc20,FKToken);
    const stkv0 = new web3.eth.Contract(stakeV0ABI,StakeFUKUV0);//LP
    const fkstake = new web3.eth.Contract(stakeFKV0ABI,StakeFUKUV0);
    const fkstakeV2 = new web3.eth.Contract(stakeFKV0ABI,StakeFUKUV2);
    setState({
      web3,
      provider,
      connected: true,
      address,
      web3Modal,
      networkId,
      fk,
      stkv0,
      fkstake,
      fkstakeV2
    });
    // window.provider = provider;
    await subscribeProvider(provider);
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      //if(state !== undefined && state.web3 !== undefined)
      //  console.log(state);  
      //setProviderData({ address: accounts[0] });
      //setState({ address: accounts[0] });
      init();
    });
    provider.on("chainChanged", async (chainId) => {
     // const { web3 } = this.state;
      //const networkId = await web3.eth.net.getId();
     // setState({ chainId, networkId });
     //setProviderData({ chainId, networkId });
     init();
    });
    provider.on("networkChanged", async (networkId) => {
      //const { web3 } = this.state;
      //const chainId = await web3.eth.chainId();
    //  await this.setState({ chainId, networkId });
    //setProviderData({ chainId, networkId });
    init();
    });
    provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
  };

  const onConnect = async () => {
    try {
      await init();
    } catch (err) {
      console.log(err);
      alert("failed to connect");
    }
  };

  const logout = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  return (
    <div className="main-div">
      <Web3Context.Provider value={state}>
        <Navbar onConnect={onConnect} logout={logout} />
        {/* <Header
        web3State={state ? state : false}
        onConnect={onConnect}
        logout={logout}
      /> */}
        <Switch>
          {/* This Route redirect to Home Page */}
          <Route exact path="/" component={Home}/>
          <Route exact path="/staking" component={Stake}>
          </Route>
          <Route exact path="/raffle" component={Raffle}>
          </Route>
          <Route exact path="/fukustaking" component={FukuStake}>
          </Route>
          <Route exact path="/stakev2" component={Stakev2}>
          </Route>
          <Route exact path="/faq" component={Faq}>
          </Route>
          <Route exact path="/leaderboard" component={Leaderboard}>
          </Route>
          {/*<Route exact path="/investor" component={Dashboard} />
          <Route exact path="/stake" component={Stake} />
          <Route exact path="/" component={Explore} />
          <Route exact path="/mintnft" component={MintNFT} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/onsale" component={Onsale} />
          <Route exact path="/profile/mynfts" component={Nfts} />
          <Route exact path="/profile/myfts" component={Myfts} />
           This line help us to deal with wrong route link */}

          <Redirect to="/" />
        </Switch>
      </Web3Context.Provider>
    </div>
  );
}

export default App;
